import React from "react";
import { Link, useLocation } from "react-router-dom";

function FooterMenu() {
  const location = useLocation();

  return (
    <nav className="menu-primary">
      <ul className="nav">
        <li
          className={
            "nav-item " +
            (location.pathname === window.location.pathname &&
            location.pathname === process.env.PUBLIC_URL + "/"
              ? "current-nav-item"
              : "")
          }
        >
          <Link to={process.env.PUBLIC_URL + "/"} title="Home">
            Accueil
          </Link>
        </li>
        <li
          className={
            "nav-item " +
            (location.pathname.includes("/about-us") ? "current-nav-item" : "")
          }
        >
          <Link to={process.env.PUBLIC_URL + "/presentation"} title="About us">
            Qui Somme Nous
          </Link>
        </li>

        <li
          className={
            "nav-item " +
            (location.pathname.includes("/services") ||
            location.pathname.includes("/service-inside")
              ? "current-nav-item"
              : "")
          }
        >
          <Link to={process.env.PUBLIC_URL + "/services"} title="Services">
            Services
          </Link>
        </li>

        {/* <li
          className={
            "nav-item " +
            (location.pathname.includes("/why-us") ? "current-nav-item" : "")
          }
        >
          <Link to={process.env.PUBLIC_URL + "/why-us"} title="Why us">
            Expertise
          </Link>
        </li> */}

        <li
          className={
            "nav-item " +
            (location.pathname.includes("/contacts") ? "current-nav-item" : "")
          }
        >
          <Link to={process.env.PUBLIC_URL + "/contacts"} title="Contacts">
            Contact
          </Link>
        </li>
      </ul>
    </nav>
  );
}

export default FooterMenu;
