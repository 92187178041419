import React, { Fragment, useEffect } from "react";
import MetaTags from "react-meta-tags";

import Loading from "../blocks/loading/Loading";
import Header from "../blocks/header/Header";
import Footer from "../blocks/footer/Footer";

import ClientsList from "../blocks/clients/ClientsList";
import image from "../assets/img/placeholder/boat.png";
import PageTitleCommon from "../blocks/page-title/PageTitleCommon";

const Client = () => {
  useEffect(() => {
    document.body.classList.add("page");
    document.body.classList.add("header-menu-true");

    return () => {
      document.body.classList.remove("page");
      document.body.classList.remove("header-menu-true");
    };
  }, []);

  return (
    <Fragment>
      <MetaTags>
        <meta charSet="UTF-8" />
        <title>BKCE International</title>

        <meta httpEquiv="x-ua-compatible" content="ie=edge" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta
          name="description"
          content="BKCE International  is a full-service neutral cargo agent for freight forwarding company. We offer custom cargo solutions for clients worldwide. Whether your shipment requirement is local or across the globe, you can trust BKCE to get it there for you on time. We speak a wide variety of languages."
        />
        <meta
          name="keywords"
          content="transport , trading , DHL , FedEx,logistics"
        />
        <meta name="robots" content="index, follow, noodp" />
        <meta name="googlebot" content="index, follow" />
        <meta name="google" content="notranslate" />
        <meta name="format-detection" content="telephone=no" />
      </MetaTags>

      <Loading />

      <Header logoColor="dark" />

      <main id="main" className="site-main">
        <PageTitleCommon
          title="Présentation"
          image={`url( ${require("../assets/img/placeholder/about-us.png")} )`}
        />

        <section id="page-content" className="block spacer p-top-xl About">
          {/* <div className="wrapper">
            <div className="content">
              <div className="clearfix">
                <div id="about-us">
                  <div className="row gutter-width-md">
                    <AboutUsPrimary isHomepage={false} />

                    <AboutUsMedia />
                  </div>
                  <br />
                  <br />
                  <AboutUsSecondary />
                </div>
              </div>
            </div>
          </div> */}

          <div
            className="col-xl-9 col-lg-9 col-md-12 col-sm-12"
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "start",
              }}
            >
              <img src={image} alt="boat" width="100%" />
            </div>

            <div className="description style clearfix spacer m-top-lg ">
              <h4 className="text-secondary">BKCE TRANSPORT</h4>
              <p
                className="description style clearfix spacer m-top-lg text-align"
                style={{
                  fontFamily: "Merriweather",
                  align: "center",
                }}
              >
                <strong
                  style={{
                    fontFamily: "Merriweather",
                  }}
                >
                  NOS CLIENT
                </strong>{" "}
                Depuis 10 ans maintenant, 
                <strong
                  style={{
                    fontFamily: "Merriweather",
                  }}
                >
                  BKCE Transport est le partenaire
                </strong>
                Transport de nombreuses entreprises opérant dans divers secteurs
                d’activité tels que l’Industrie, l’Automobile, le Textile,
                l’Aéronautique, le Transport, le BTP et la Distribution.
                <br /> <br />
                Et ce, grâce au large choix de produits Transport proposés : 
                <strong>Messagerie, Affrètement et Express… </strong>De par leur
                singularité, les besoins et les objectifs de nos clients sont
                spécifiques en matière de Transport. <br /> <br />
                Nos produits Transport permettent de répondre à vos besoins tout
                en réduisant les budgets transport et en optimisant la supply
                chain. Nos clients sont d’ailleurs la meilleure preuve de
                l’efficacité de nos solutions.
              </p>
            </div>
          </div>
        </section>
        <section id="clients" className="block spacer p-top-xl">
          <ClientsList />
        </section>
      </main>

      <Footer />
    </Fragment>
  );
};

export default Client;
