import React, { Fragment, useEffect } from "react";
import MetaTags from "react-meta-tags";

import Loading from "../blocks/loading/Loading";
import Header from "../blocks/header/Header";
import Footer from "../blocks/footer/Footer";

import ClientsList from "../blocks/clients/ClientsList";
import image from "../assets/img/placeholder/boat.png";
import PageTitleCommon from "../blocks/page-title/PageTitleCommon";

const Presentation = () => {
  useEffect(() => {
    document.body.classList.add("page");
    document.body.classList.add("header-menu-true");

    return () => {
      document.body.classList.remove("page");
      document.body.classList.remove("header-menu-true");
    };
  }, []);

  return (
    <Fragment>
      <MetaTags>
        <meta charSet="UTF-8" />
        <title>BKCE International</title>

        <meta httpEquiv="x-ua-compatible" content="ie=edge" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta
          name="description"
          content="BKCE International  is a full-service neutral cargo agent for freight forwarding company. We offer custom cargo solutions for clients worldwide. Whether your shipment requirement is local or across the globe, you can trust BKCE to get it there for you on time. We speak a wide variety of languages."
        />
        <meta
          name="keywords"
          content="transport , trading , DHL , FedEx,logistics"
        />
        <meta name="robots" content="index, follow, noodp" />
        <meta name="googlebot" content="index, follow" />
        <meta name="google" content="notranslate" />
        <meta name="format-detection" content="telephone=no" />
      </MetaTags>

      <Loading />

      <Header logoColor="dark" />

      <main id="main" className="site-main">
        <PageTitleCommon
          title="Présentation"
          image={`url( ${require("../assets/img/placeholder/prese.jpg")} )`}
        />

        <section id="page-content" className="block spacer p-top-xl About">
          {/* <div className="wrapper">
            <div className="content">
              <div className="clearfix">
                <div id="about-us">
                  <div className="row gutter-width-md">
                    <AboutUsPrimary isHomepage={false} />

                    <AboutUsMedia />
                  </div>
                  <br />
                  <br />
                  <AboutUsSecondary />
                </div>
              </div>
            </div>
          </div> */}

          <div
            className="col-xl-9 col-lg-9 col-md-12 col-sm-12"
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
            }}
          >
            <div></div>

            <div className="description style clearfix spacer m-top-lg ">
              <h4 className="text-secondary">BKCE TRANSPORT</h4>
              <p
                className="description style clearfix spacer m-top-lg text-align"
                style={{
                  fontFamily: "Merriweather",
                  align: "center",
                }}
              >
                <strong
                  style={{
                    fontFamily: "Merriweather",
                  }}
                >
                  BKCE Transport
                </strong>{" "}
                est une société Commissionnaire de transport qui a pour objectif
                d’assurer l’acheminement de vos marchandises en France et en
                Europe, et ce dans les meilleures conditions. Qu’il s’agisse de
                pli, de colis, de palette, de lot partiel ou de camion complet,
                nous vous proposons la solution transport adéquate. De même,
                nous nous adaptons à vos impératifs de livraison en vous
                proposant un large panel de produit Transport : 
                <strong
                  style={{
                    fontFamily: "Merriweather",
                  }}
                >
                  Messagerie, Affrètement, Express…
                </strong>{" "}
                <br /> <br />
                Positionné à l’origine dans l’assistance aéroportuaire avec des
                hubs au départ et à l’arrivé des principaux ports et aéroports
                français, notre expertise s’est développé et renforcé au travers
                de notre réseau (contrôlé et audité) nous permettant de vous
                proposer des solutions complètes et spécifiques (température
                dirigée, transport de produits dangereux, intervention sur les
                foires et expositions, transport exceptionnel, stockage de vos
                marchandises). Nous souhaitons devenir votre partenaire
                transport et ainsi vous soulager de cette gestion en respectant
                vos demandes. <br /> <br />
                <strong
                  style={{
                    fontFamily: "Merriweather",
                  }}
                >
                  Notre équipe
                </strong>{" "}
                se met à votre disposition pour vous écouter, vous orienter vers
                la solution la plus adéquate et vous satisfaire dans toutes vos
                demandes. Vous pouvez compter sur un service opérationnel, une
                réactivité et une efficacité déjà éprouvée du lundi au
                vendredi de 9h à 12h30 et de 14h à 18h.
              </p>
            </div>
          </div>
        </section>
        <section id="clients" className="block spacer p-top-xl">
          <ClientsList />
        </section>
      </main>

      <Footer />
    </Fragment>
  );
};

export default Presentation;
