import React from "react";

const Copyright = () => {
  return (
    <div className="copyright">
      <p>© 2023 Logistics & Transportation BKCE Transports</p>
    </div>
  );
};

export default Copyright;
