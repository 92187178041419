import React, { Fragment, useEffect } from "react";
import MetaTags from "react-meta-tags";

import Loading from "../blocks/loading/Loading";
import Header from "../blocks/header/Header";
import Footer from "../blocks/footer/Footer";
import tick from "../assets/img/bg/blueTick.png";
import ClientsList from "../blocks/clients/ClientsList";
import image from "../assets/img/placeholder/sec.jpg";
import PageTitleCommon from "../blocks/page-title/PageTitleCommon";

const Secteur = () => {
  useEffect(() => {
    document.body.classList.add("page");
    document.body.classList.add("header-menu-true");

    return () => {
      document.body.classList.remove("page");
      document.body.classList.remove("header-menu-true");
    };
  }, []);

  return (
    <Fragment>
      <MetaTags>
        <meta charSet="UTF-8" />
        <title>BKCE International</title>

        <meta httpEquiv="x-ua-compatible" content="ie=edge" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta
          name="description"
          content="BKCE International  is a full-service neutral cargo agent for freight forwarding company. We offer custom cargo solutions for clients worldwide. Whether your shipment requirement is local or across the globe, you can trust BKCE to get it there for you on time. We speak a wide variety of languages."
        />
        <meta
          name="keywords"
          content="transport , trading , DHL , FedEx,logistics"
        />
        <meta name="robots" content="index, follow, noodp" />
        <meta name="googlebot" content="index, follow" />
        <meta name="google" content="notranslate" />
        <meta name="format-detection" content="telephone=no" />
      </MetaTags>

      <Loading />

      <Header logoColor="dark" />

      <main id="main" className="site-main">
        <PageTitleCommon
          title="Secteurs D’activité"
          image={`url( ${require("../assets/img/placeholder/secteru.jpg")} )`}
        />

        <section id="page-content" className="block spacer p-top-xl About">
          {/* <div className="wrapper">
            <div className="content">
              <div className="clearfix">
                <div id="about-us">
                  <div className="row gutter-width-md">
                    <AboutUsPrimary isHomepage={false} />

                    <AboutUsMedia />
                  </div>
                  <br />
                  <br />
                  <AboutUsSecondary />
                </div>
              </div>
            </div>
          </div> */}

          <div
            className="col-xl-9 col-lg-9 col-md-12 col-sm-12"
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "start",
              }}
            >
              <img src={image} alt="boat" width="100%" />
            </div>

            <div className="description style clearfix spacer m-top-lg ">
              <h4 className="text-secondary">BKCE TRANSPORT</h4>
              <p
                className="description style clearfix spacer m-top-lg text-align"
                style={{
                  fontFamily: "Merriweather",
                  align: "center",
                }}
              >
                <p
                  className="tick"
                  style={{ fontFamily: "Merriweather", fontSize: "22px" }}
                >
                  <img
                    src={tick}
                    alt="tickimg"
                    width="20px"
                    style={{ marginRight: "15px" }}
                  ></img>
                  Transport de marchandises standard : messagerie, affrètement.
                </p>
                <p
                  className="tick"
                  style={{ fontFamily: "Merriweather", fontSize: "22px" }}
                >
                  <img
                    src={tick}
                    alt="tickimg"
                    width="20px"
                    style={{ marginRight: "15px" }}
                  ></img>
                  Transport de marchandises sensibles : dangereux, température
                  dirigée, exceptionnel.
                </p>
                <p
                  className="tick"
                  style={{ fontFamily: "Merriweather", fontSize: "22px" }}
                >
                  <img
                    src={tick}
                    alt="tickimg"
                    width="20px"
                    style={{ marginRight: "15px" }}
                  ></img>
                  Transport dédié : mise à disposition de véhicule dédié à votre
                  marchandise sans rupture charge.
                </p>
                <p
                  className="tick"
                  style={{ fontFamily: "Merriweather", fontSize: "22px" }}
                >
                  <img
                    src={tick}
                    alt="tickimg"
                    width="20px"
                    style={{ marginRight: "15px" }}
                  ></img>
                  Livraison sur salons, foires ou expositions
                </p>
              </p>
            </div>
          </div>
        </section>
        <section id="clients" className="block spacer p-top-xl">
          <ClientsList />
        </section>
      </main>

      <Footer />
    </Fragment>
  );
};

export default Secteur;
