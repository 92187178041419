import React, { Fragment, useEffect } from "react";
import MetaTags from "react-meta-tags";

import Loading from "../blocks/loading/Loading";
import Header from "../blocks/header/Header";
import Footer from "../blocks/footer/Footer";

import ClientsList from "../blocks/clients/ClientsList";
import image from "../assets/img/placeholder/boat.png";
import PageTitleCommon from "../blocks/page-title/PageTitleCommon";

const Savoir = () => {
  useEffect(() => {
    document.body.classList.add("page");
    document.body.classList.add("header-menu-true");

    return () => {
      document.body.classList.remove("page");
      document.body.classList.remove("header-menu-true");
    };
  }, []);

  return (
    <Fragment>
      <MetaTags>
        <meta charSet="UTF-8" />
        <title>BKCE International</title>

        <meta httpEquiv="x-ua-compatible" content="ie=edge" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta
          name="description"
          content="BKCE International  is a full-service neutral cargo agent for freight forwarding company. We offer custom cargo solutions for clients worldwide. Whether your shipment requirement is local or across the globe, you can trust BKCE to get it there for you on time. We speak a wide variety of languages."
        />
        <meta
          name="keywords"
          content="transport , trading , DHL , FedEx,logistics"
        />
        <meta name="robots" content="index, follow, noodp" />
        <meta name="googlebot" content="index, follow" />
        <meta name="google" content="notranslate" />
        <meta name="format-detection" content="telephone=no" />
      </MetaTags>

      <Loading />

      <Header logoColor="dark" />

      <main id="main" className="site-main">
        <PageTitleCommon
          title="Savoir Faire"
          image={`url( ${require("../assets/img/placeholder/savoir.jpg")} )`}
        />

        <section id="page-content" className="block spacer p-top-xl About">
          {/* <div className="wrapper">
            <div className="content">
              <div className="clearfix">
                <div id="about-us">
                  <div className="row gutter-width-md">
                    <AboutUsPrimary isHomepage={false} />

                    <AboutUsMedia />
                  </div>
                  <br />
                  <br />
                  <AboutUsSecondary />
                </div>
              </div>
            </div>
          </div> */}

          <div
            className="col-xl-9 col-lg-9 col-md-12 col-sm-12"
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
            }}
          >
            <div></div>

            <div className="description style clearfix spacer m-top-lg ">
              <h4 className="text-secondary">
                BKCE Transport  se propose de devenir votre partenaire
                transport.
              </h4>
              <p
                className="description style clearfix spacer m-top-lg text-align"
                style={{
                  fontFamily: "Merriweather",
                  align: "center",
                }}
              >
                Nous organisons l’acheminement de vos marchandises et assurons
                le suivi et la bonne exécution du transport en nous appuyant sur
                un réseau fiable et contrôlé avec soin.
                <br /> <br />
                Nous expédions pour vous en messagerie vos colis, en affrètement
                vos expédition plus conséquente et en express (camion dédié pour
                vos marchandises uniquement) vos marchandises urgente à travers
                la France ou en l’Europe. Nous sommes capable de répondre à vos
                exigences : véhicule adéquat, RDV, température dirigée,
                transport de produits dangereux , intervention sur les foire et
                exposition, stockage de vos marchandises, chargement en vrac,
                palettisation, transport exceptionnel. <br /> <br />
                <strong
                  style={{
                    fontFamily: "Merriweather",
                  }}
                >
                  Nous sommes une équipe d’expert du transport routier
                </strong>{" "}
                Attentif et à l’écoute de vos demandes et contraintes, nous
                mettons tous les moyens possibles pour répondre à vos attentes
                et vous suggérons la meilleure solution. Que ce soit en termes
                de coût ou de service.
                <br />
                En tant que professionnels, nous connaissons et savons nous
                adapter aux aléas inhérents au transport (prise de RDV,
                modification des colisages, des adresses, attente, échange
                palette) et en cas de nécessité, nous pouvons assurer le
                stockage de vos marchandises.
              </p>
            </div>
          </div>
        </section>
        <section id="clients" className="block spacer p-top-xl">
          <ClientsList />
        </section>
      </main>

      <Footer />
    </Fragment>
  );
};

export default Savoir;
