import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Collapse } from "react-bootstrap";
class HeaderMenuPrimary extends Component {
  constructor(props) {
    super();
    this.state = {
      over: false,
      isDropdownOpen: false,
      open: false,
    };
  }

  toggleDropdown = () => {
    this.setState((prevState) => ({
      isDropdownOpen: !prevState.isDropdownOpen,
    }));
  };

  handleOver = () => {
    this.setState({ over: !this.state.over });
  };
  handleClick = () => {
    this.setState((prevState) => ({
      open: !prevState.open,
    }));
  };

  modalOff = () => {
    let e = document.getElementById("close-modal");

    if (e) {
      e.click();
    }
  };

  render() {
    const { isDropdownOpen } = this.state;
    const { open } = this.state;
    console.log(isDropdownOpen, "dddddddddddddddddddddddddddd");
    return (
      <nav className="menu-primary">
        <ul className="nav">
          <li className="nav-item">
            <Link to={process.env.PUBLIC_URL + "/"} title="Home">
              Accueil
            </Link>
          </li>

          <li
            style={{ height: "20%" }}
            className={
              "nav-item " +
              (window.location.pathname.includes("/about-us")
                ? "current-nav-item"
                : "") +
              "nav-item dropdown"
            }
          >
            <a
              rguments
              title="About us"
              onClick={this.toggleDropdown}
              to="#"
              class="dropdown-toggle"
            >
              Qui Somme Nous
            </a>
            {isDropdownOpen && (
              <div className="dropdown-custom-icon dropdown-icon">
                <li class="dropdown-item">
                  <Link to={process.env.PUBLIC_URL + "/presentation"}>
                    Présentation
                  </Link>
                </li>
                <li class="dropdown-item">
                  <Link to={process.env.PUBLIC_URL + "/secteur"}>
                    Secteurs D’activité
                  </Link>
                </li>
                <div class="dropdown-divider"></div>
                <li class="dropdown-item">
                  <Link to={process.env.PUBLIC_URL + "/savoir"}>
                    Savoir Faire
                  </Link>
                </li>
                <div class="dropdown-divider"></div>
                <li class="dropdown-item">
                  <Link to={process.env.PUBLIC_URL + "/client"}>Client</Link>
                </li>
              </div>
            )}
          </li>

          <li
            className={
              "nav-item " +
              (window.location.pathname.includes("/services") ||
              window.location.pathname.includes("/service-inside")
                ? "current-nav-item"
                : "")
            }
          >
            <Link to={process.env.PUBLIC_URL + "/services"} title="Services">
              Services
            </Link>
          </li>
          <li
            className={
              "nav-item " +
              (window.location.pathname.includes("/why-us")
                ? "current-nav-item"
                : "")
            }
          >
            <Link to={process.env.PUBLIC_URL + "/why-us"} title="Why us">
              Expertise
            </Link>
          </li>

          <li
            className={
              "nav-item " +
              (window.location.pathname.includes("/contacts")
                ? "current-nav-item"
                : "")
            }
          >
            <Link to={process.env.PUBLIC_URL + "/contacts"} title="Contacts">
              Contact
            </Link>
          </li>
        </ul>
      </nav>
    );
  }
}

export default HeaderMenuPrimary;
