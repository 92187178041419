import React, { Fragment, useEffect, useState } from "react";
import MetaTags from "react-meta-tags";

import Loading from "../blocks/loading/Loading";
import Header from "../blocks/header/Header";
import Footer from "../blocks/footer/Footer";

import { useParams } from "react-router-dom";

import PageTitleCommon from "../blocks/page-title/PageTitleCommon";
import ClientsList from "../blocks/clients/ClientsList";
import ServiceInsideSwiper from "../blocks/service-inside/ServiceInsideSwiper";
import ServiceInsideWidget from "../blocks/service-inside/ServiceInsideWidget";
import ServiceData from "../data/service-inside/servicesData.json";

const ServiceInside = () => {
  const { serviceId } = useParams();
  const [service, setService] = useState();

  useEffect(() => {
    setService(ServiceData.find((service) => service.id === Number(serviceId)));

    document.body.classList.add("single");
    document.body.classList.add("single-adveits_service");

    return () => {
      document.body.classList.remove("single");
      document.body.classList.remove("single-adveits_service");
    };
  }, [serviceId]);
  console.log(service);
  return (
    <Fragment>
      <MetaTags>
        <meta charSet="UTF-8" />
        <title>
          BKCE International for logistics-transport-trading,Service
        </title>

        <meta httpEquiv="x-ua-compatible" content="ie=edge" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta
          name="description"
          content="Air freight ,ocean Freight ,project cargo,Multi Modal Transport ,warehousing and customs brokrage"
        />
        <meta
          name="keywords"
          content="transport , trading , DHL , FedEx,logistics"
        />
        <meta name="robots" content="index, follow, noodp" />
        <meta name="googlebot" content="index, follow" />
        <meta name="google" content="notranslate" />
        <meta name="format-detection" content="telephone=no" />
      </MetaTags>

      <Loading />

      <Header logoColor="dark" />

      <main id="main" className="site-main">
        <PageTitleCommon
          title={service?.title}
          image={`url( ${service?.imageTitle} )`}
        />

        <section id="page-content" className="spacer p-top-xl">
          <div className="wrapper">
            <div className="content">
              <div id="single">
                <div
                  className="row gutter-width-md single-content"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <div
                    className="col-xl-9 col-lg-9 col-md-12 col-sm-12"
                    style={{
                      marginBottom: "20px",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <div
                      className="description style clearfix spacer m-top-lg text-align "
                      style={{
                        fontFamily: "Merriweather",
                        marginBottom: "20px",
                        marginRight: "30px",
                      }}
                    >
                      <p>{service?.description}</p>
                      {service?.items2 && (
                        <ul>
                          {service?.items2.map((item) => (
                            <li>{item}</li>
                          ))}
                        </ul>
                      )}
                    </div>
                    <div>
                      <p
                        style={{ fontFamily: "Merriweather" }}
                        className="description style clearfix spacer m-top-lg text-align"
                      >
                        {service?.description2}
                      </p>
                      {service?.items && (
                        <ul>
                          {service?.items.map((item) => (
                            <li style={{ fontFamily: "Merriweather" }}>
                              {item}
                            </li>
                          ))}
                        </ul>
                      )}
                    </div>
                  </div>

                  <div className="col-xl-9 col-lg-9 col-md-12 col-sm-12">
                    <ServiceInsideSwiper image={service?.imgLink} />
                  </div>

                  {/* <div className="col-xl-3 col-lg-3 col-md-12 col-sm-12">
                    <aside id="aside" className="widget-area style-default">
                      <div id="service" className="widget_service">
                        <ServiceInsideWidget order={service?.order} />
                      </div>
                    </aside>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
          <section id="clients" className="block spacer p-top-xl">
            <ClientsList />
          </section>
        </section>
      </main>

      <Footer />
    </Fragment>
  );
};

export default ServiceInside;
