import React, { Fragment, Component } from "react";
import MetaTags from "react-meta-tags";

import Loading from "../blocks/loading/Loading";
import HomeHeader from "../blocks/header/HomeHeader";
import Footer from "../blocks/footer/Footer";

import PageTitleHome from "../blocks/page-title/PageTitleHome";

import ClientsList from "../blocks/clients/ClientsList";
import Global from "../blocks/Global/Global";
// import ReviewsContent from "../blocks/reviews/ReviewsContent";

class Home extends Component {
  constructor() {
    super();
    this.state = {
      isFixed: false,
      isStyle: { width: "90%", marginLeft: "60px", marginTop: "50px" },
    };
  }

  _isMounted = false;

  changeLogo = () => {
    if (this._isMounted) {
      window.addEventListener("scroll", () => {
        if (document.body.classList.contains("header-fixed")) {
          this.setState({
            isFixed: true,
          });
        } else {
          this.setState({
            isFixed: false,
          });
        }
      });
    }
  };

  componentDidMount = () => {
    document.body.classList.add("home");
    document.body.classList.add("header-menu-true");
    document.body.classList.add("header-absolute-true");
    document.body.classList.add("header-fixed-true");
    document.body.classList.add("button-clicked-true");

    this._isMounted = true;
    this.changeLogo();

    // window.addEventListener(
    //   "scroll",
    //   function (event) {
    //     if (that.isInViewport(services)) {
    //       document.body.classList.add("header-fixed");
    //       header.classList.add("animated");
    //       header.classList.add("slideInDown");
    //     } else {
    //       document.body.classList.remove("header-fixed");
    //       header.classList.remove("animated");
    //       header.classList.remove("slideInDown");
    //     }
    //   },
    //   false
    // );
  };

  componentWillUnmount = () => {
    document.body.classList.remove("home");
    document.body.classList.remove("header-menu-true");
    document.body.classList.remove("header-absolute-true");
    document.body.classList.remove("header-fixed-true");
    document.body.classList.remove("button-clicked-true");

    this._isMounted = false;
  };

  // isInViewport = (services) => {
  //   let bounding = services.getBoundingClientRect();

  //   if (window.screenTop - bounding.top > -20) {
  //     return true;
  //   }
  // };

  render() {
    return (
      <Fragment>
        <MetaTags>
          <meta charSet="UTF-8" />
          <title>
            BKCE International for logistics-transport-trading, Home
          </title>

          <meta httpEquiv="x-ua-compatible" content="ie=edge" />
          <meta name="viewport" content="width=device-width, initial-scale=1" />
          <meta
            name="description"
            content="BKCE International is a total transportation and logistics provider with global agency alliance
      Our network of partners spans each of the seven continents. This allows us to transport the simple to the most complex of shipments quickly and efficiently for our customers."
          />
          <meta
            name="keywords"
            content="logistics , transport , trading , DHL , FedEx"
          />
          <meta name="robots" content="index, follow, noodp" />
          <meta name="googlebot" content="index, follow" />
          <meta name="google" content="notranslate" />
          <meta name="format-detection" content="telephone=no" />
        </MetaTags>

        <Loading />

        <HomeHeader
          logoColor={this.state.isFixed ? "dark" : "light"}
          navStyle={
            this.state.isFixed
              ? {}
              : { width: "90%", marginLeft: "60px", marginTop: "50px" }
          }
        />

        <main id="main" className="site-main">
          <PageTitleHome />

          <section className="block spacer p-top-xl ">
            <div className="title-home">
              <h4
                style={{
                  fontFamily: "Merriweather",
                  color: "#00308F",
                  fontWeight: "bold",
                }}
              >
                BKCE International is a total transportation and logistics{" "}
                <br />
                provider with global agency alliance
              </h4>
            </div>
            <br />

            <br />
            <div className="wrapper-home">
              <div className="text homepara">
                <p>
                  En tant que prestataire de service, notre politique qualité
                  est axée vers nos clients. Ce qu’ils attendent de nous est
                  d’être conseillés, et orientés.
                </p>
              </div>
              <div className="text homepara">
                <p>
                  Nous nous devons d’être à l’écoute de nos clients avec rigueur
                  et professionnalisme pour nous adapter à leurs besoins, les
                  anticiper et construire avec eux une relation solide et de
                  longue durée.
                </p>
              </div>
            </div>
          </section>

          {/* <section id="services" className="block spacer p-top-xl">
                  <div className="wrapper">
                    <div className="title">
                      <h2>
                        <a
                          title="Services"
                          className="transform-scale-h"
                          href={process.env.PUBLIC_URL + "/services"}
                        >
                          Services
                          <i className="fas fas-space-l fa-long-arrow-alt-right align-top"></i>
                        </a>
                      </h2>
                    </div>

                    <ServicesContent />
                  </div>
                </section> */}

          {/* <section id="about-us" className="block spacer p-top-xl">
                  <div className="wrapper">
                    <div className="row gutter-width-md">
                      <AboutUsPrimary isHomepage={true} />
                    </div>
                  </div>
                </section> */}

          {/* <section id="why-us" className="block spacer p-top-xl">
                  <div className="bg-light spacer p-top-lg p-bottom-lg">
                    <div className="wrapper">
                      <div className="title">
                        <h2>
                          <Link
                            className="transform-scale-h"
                            to={process.env.PUBLIC_URL + "/why-us"}
                          >
                            Expertise
                            <i className="fas fas-space-l fa-long-arrow-alt-right align-top"></i>
                          </Link>
                        </h2>
                      </div>
                    </div>

                    <WhyUsContent />
                  </div>
                </section> */}

          {/* <section id="gallery" className="block spacer p-top-xl">
                  <div className="wrapper">
                    <div className="title">
                      <h2>
                        <a
                          title="Gallery"
                          className="transform-scale-h"
                          href={process.env.PUBLIC_URL + "/gallery-inside"}
                        >
                          Gallery
                          <i className="fas fas-space-l fa-long-arrow-alt-right align-top"></i>
                        </a>
                      </h2>
                    </div>
                  </div>

                  <GalleryList isHome={true} />
                </section> */}

          {/* <section id="reviews" className="block  p-top-xl">
                  <div className="wrapper">
                    <div className="title">
                      <h2>
                        <a
                          title="Customer reviews"
                          className="transform-scale-h"
                          href={process.env.PUBLIC_URL + "/reviews"}
                        >
                          Customer reviews
                          <i className="fas fas-space-l fa-long-arrow-alt-right align-top"></i>
                        </a>
                      </h2>
                    </div>

                    <ReviewsContent />
                  </div>
                </section> */}

          <section id="news" className="block spacer p-top-xl">
            <div className="wrapper">
              <div className="title">
                {/* <h2>
                        <a
                          title="Current news"
                          className="transform-scale-h"
                          href={process.env.PUBLIC_URL + "/news"}
                        >
                          Global Network
                          <i className="fas fas-space-l fa-long-arrow-alt-right align-top"></i>
                        </a>
                      </h2> */}
              </div>

              <Global />
            </div>
          </section>

          <section id="clients" className="block spacer p-top-xl">
            <div className="wrapper">
              <div className="title">
                <h2>Our clients</h2>
              </div>

              <ClientsList />
            </div>
          </section>

          <section id="contacts" className="block spacer p-top-xl">
            {/* <div className="wrapper">
                    <div className="title">
                      <h2>
                        <a
                          title="Contacts"
                          className="transform-scale-h"
                          href={process.env.PUBLIC_URL + "/contacts"}
                        >
                          Contacts
                          <i className="fas fas-space-l fa-long-arrow-alt-right align-top"></i>
                        </a>
                      </h2>
                    </div>
                  </div>
                  <ContactsContent /> */}
          </section>
          <br />
        </main>

        <Footer />
      </Fragment>
    );
  }
}

export default Home;
