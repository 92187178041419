import React from "react";

const ClientsList = () => {
  return (
    <div className="client-container">
      <div className="client-item box">
        <div className="">
          <img
            src="assets/img/logo/geo.jpg"
            alt="Logo"
            width="120"
            height="100"
          />
        </div>
      </div>

      <div className="client-item box">
        <div className="client-body">
          <img
            src="assets/img/logo/11_img.PNG"
            alt="Logo"
            width="90"
            height="100"
          />
        </div>
      </div>

      <div className="client-item box">
        <div className="client-body">
          <img
            src="assets/img/logo/15_img.PNG"
            alt="Logo"
            width="90"
            height="100"
          />
        </div>
      </div>

      <div className="client-item box">
        <div className="client-body">
          <img
            src="assets/img/logo/12_img.PNG"
            alt="Logo"
            width="90"
            height="100"
          />
        </div>
      </div>

      <div className="client-item box">
        <div className="client-body">
          <img
            src="assets/img/logo/14_img.PNG"
            alt="Logo"
            width="90"
            height="100"
          />
        </div>
      </div>
      <div className="client-item box">
        <div className="client-body">
          <img
            src="assets/img/logo/16_img.PNG"
            alt="Logo"
            width="90"
            height="100"
          />
        </div>
      </div>
      <div className="client-item box">
        <div className="client-body">
          <img
            src="assets/img/logo/fedex.jpg"
            alt="Logo"
            width="90"
            height="100"
          />
        </div>
      </div>
      <div className="client-item box">
        <div className="client-body">
          <img
            src="assets/img/logo/dhl.PNG"
            alt="Logo"
            width="90"
            height="100"
          />
        </div>
      </div>
      <div className="client-item box">
        <div className="client-body">
          <img
            src="assets/img/logo/LEC.PNG"
            alt="Logo"
            width="90"
            height="100"
          />
        </div>
      </div>
      <div className="client-item box">
        <div className="client-body">
          <img
            src="assets/img/logo/VIA.webp"
            alt="Logo"
            width="90"
            height="100"
          />
        </div>
      </div>
      <div className="client-item box">
        <div className="client-body">
          <img
            src="assets/img/logo/chro.PNG"
            alt="Logo"
            width="100"
            height="100"
          />
        </div>
      </div>
    </div>
  );
};

export default ClientsList;
