import React from "react";
import logo from "../../assets/img/logo/bkcefr.png";
const HeaderLogo = ({ logoColor }) => {
  return (
    <a
      className="logo logo-primary transform-scale-h"
      title="Logo"
      href={process.env.PUBLIC_URL + "/"}
    >
      <img
        width="160"
        height=""
        className={"logo-primary-" + (logoColor === "light" ? "light" : "dark")}
        //  src={ 'assets/img/logo/merge-' + ( logoColor === 'light' ? '2-light' : '1-dark' ) + ".jpg" } alt="Logo"
        src={logo}
        alt="Logo"
      />
    </a>
  );
};

export default HeaderLogo;
